import ApiService from "@/services/api.service";
import logger from "@/logger";

// action types
export const GET_PLACES = "getplaces";
export const GET_PLACE = "getPlace";
export const ADD_PLACE = "addPlace";
export const UPDATE_PLACE = "updatePlace";
export const DELETE_PLACE = "deletePlace";

// mutation types
export const SET_PLACES = "setplaces";
export const SET_PLACE = "setPlace";

const state = {
  errors: null,
  placeList: [],
  placeInfo: {}
};

const getters = {};

const actions = {
  [GET_PLACES](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.get("/management/v1/artpool/places")
        .then(({data}) => {
          context.commit(SET_PLACES, data.data);
          resolve(data.data);
        })
        .catch(({ response }) => {
          logger.error(response);
          reject(response);
        });
    })
  },
  [GET_PLACE](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.get("/management/v1/artpool/places/" + payload)
        .then(({data}) => {
          context.commit(SET_PLACE, data.data);
          resolve(data.data);
        })
        .catch(({ response }) => {
          logger.error(response);
          reject(response);
        });
    })
  },
  [ADD_PLACE](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post("/management/v1/artpool/places", payload)
        .then(({data}) => {
          resolve(data.data);
        })
        .catch(({ response }) => {
          logger.error(response);
          reject(response);
        });
    })
  },
  [UPDATE_PLACE](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.put("/management/v1/artpool/places/" + payload.idx, payload)
        .then(({data}) => {
          resolve(data.data);
        })
        .catch(({ response }) => {
          logger.error(response);
          reject(response);
        });
    })
  },
  [DELETE_PLACE](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.delete("/management/v1/artpool/places/" + payload)
        .then(({data}) => {
          resolve(data.data);
        })
        .catch(({ response }) => {
          logger.error(response);
          reject(response);
        });
    })
  },
}

const mutations = {
  [SET_PLACES](state, placeList) {
    state.placeList = placeList;
  },
  [SET_PLACE](state, placeInfo) {
    state.placeInfo = placeInfo;
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
